import { Injectable } from '@angular/core';
@Injectable({
	providedIn: 'root'
})
export class PriceableFieldCalServ {

	/**********Priceable custom fields functions ************/


	/**
	 * Function takes two parameters:
	 * @param BKFrmValue
	 * @param fieldInpObj
	 * An object obj is then created with properties taken from fieldInpObj using optional chaining (?.).
	 * Next, an if statement checks if BKFrmValue has a property called priceable_custom_fields.
	 * If it does, the function calcPriceableFieldAccToType is called with the BKFrmValue and obj as parameters.
	 * The result of calcPriceableFieldAccToType is an object with properties price, time, and priceExcFromServPrvTotal.
	 * These properties are added to the corresponding variables using += assignment operator.
	 * Finally, an object is returned with the values of price, time, and priceExcFromServPrvTotal.
	 */
	public calcPriceableCustomFields(BKFrmValue: any, fieldInpObj: any){
		let price = 0;
		let time = 0;
		let priceExcFromServPrvTotal = 0;
		let obj = {
			type : fieldInpObj?.type,
			applyTo : fieldInpObj?.applyTo,
			seprateFieldValue : fieldInpObj?.seprateFieldValue,
			outType: fieldInpObj?.outType,
		}
		if(BKFrmValue?.priceable_custom_fields){
			let priceTimeObj = this.calcPriceableFieldAccToType(BKFrmValue, obj)
			price += priceTimeObj.price;
			time += priceTimeObj.time;
			priceExcFromServPrvTotal += priceTimeObj.priceExcFromServPrvTotal;
		}
		return {
			price,
			time,
			priceExcFromServPrvTotal,
		}
	}
	/**
	 * Function calcPriceableFieldAccToType, takes two parameters: @param BKFrmValue and @param obj.
	 * function then checks if obj has a property called type using optional chaining. If it does, it calls the specificTypeCustomFieldLoop function with BKFrmValue and obj as parameters.
	 * The result of specificTypeCustomFieldLoop is an object with properties price, time, and priceExcFromServPrvTotal. These properties are added to the corresponding variables using the += assignment operator.
	 * If obj does not have a property called type, the function proceeds to check if obj has a property called isProvider. If it does, it adds 'non_taxable' and 'after_discounted_total' to an array called typeArray.
	 * The function then iterates over typeArray and sets obj.type to the current element. It calls the specificTypeCustomFieldLoop function with BKFrmValue and obj as parameters.
	 * The result of specificTypeCustomFieldLoop is again an object with properties price, time, and priceExcFromServPrvTotal. These properties are added to the corresponding variables using the += assignment operator.
	 * Finally, an object is returned with the values of price, time, and priceExcFromServPrvTotal.
	 */
	public calcPriceableFieldAccToType(BKFrmValue: any, obj: any){
		let price = 0;
		let time = 0;
		let priceExcFromServPrvTotal = 0;
		if(obj?.type){
			let priceTimeObj  = this.specificTypeCustomFieldLoop(BKFrmValue, obj);
			price = priceTimeObj.price;
			time = priceTimeObj.time;
			priceExcFromServPrvTotal = priceTimeObj.priceExcFromServPrvTotal;
		}else{
			let typeArray = ['include_in_freq_disc', 'exempt_from_freq_disc'];
			if(obj?.isProvider || obj?.outType == 'time'){
				typeArray.push('non_taxable', 'after_discounted_total');
			}
			for (let fieldType of typeArray){
				obj.type = fieldType;
				let priceTimeObj  = this.specificTypeCustomFieldLoop(BKFrmValue, obj);
				price += priceTimeObj.price;
				time += priceTimeObj.time;
				priceExcFromServPrvTotal += priceTimeObj.priceExcFromServPrvTotal;
			}
		}
		return {
			price,
			time,
			priceExcFromServPrvTotal,
		}
	}
	/**
	 * Function that calculates the price, time, and total price excluding from service provider based on certain conditions and inputs.
	 * It iterates through a list of custom fields and performs calculations on each field. The final result is returned as an object with the calculated values.
	 */
	public specificTypeCustomFieldLoop(BKFrmValue: any, obj: any, isProvider: boolean = false){
		let price = 0;
		let time = 0;
		let priceExcFromServPrvTotal = 0;
		isProvider = obj?.isProvider ? obj?.isProvider : false;
		if(BKFrmValue?.priceable_custom_fields[obj?.type]){
			for(let field of BKFrmValue.priceable_custom_fields[obj?.type]){
				let priceTimeobj = this.specificTypeFieldLoopPart(BKFrmValue, field, obj, isProvider)
				price += priceTimeobj.price;
				time += priceTimeobj.time;
				priceExcFromServPrvTotal += priceTimeobj.priceExcFromServPrvTotal;
			}
		}
		return {
			price,
			time,
			priceExcFromServPrvTotal,
		}
	}
	/**
	 * Function takes four parameters:
	 * @param BKFrmValue - a value of any type
	 * @param field - an object of any type
	 * @param obj - an object of any type
	 * @param isProvider - a boolean value
	 *
	 * Inside the function, several variables (price, time, priceExcFromServPrvTotal, locType) are declared and initialized to 0 or null.
	 * Then, there are a series of conditional statements and calculations based on the values of the parameters and objects.
	 * Depending on the conditions, the variables price, time, and priceExcFromServPrvTotal may be updated with certain values.
	 * The function finally returns an object with the properties price, time, and priceExcFromServPrvTotal, which represent the calculated values.
	 */
	public specificTypeFieldLoopPart(BKFrmValue: any, field: any, obj: any, isProvider: any): any{
		let price = 0;
		let time = 0;
		let priceExcFromServPrvTotal = 0;
		let locType = BKFrmValue?.location_type;
		let objApplyTo = obj?.applyTo;
		let fieldApplyTo = field?.apply_to;
		let objSepFieldValue = obj?.seprateFieldValue;
		let priceTimeobj : any;
		if((objApplyTo === fieldApplyTo) || objApplyTo === ''){
			if(isProvider){
				let providerPayType = field.provider_pay_type;
				if(this.prvdrPayNotNone(providerPayType) && this.isFieldValue(field, obj.type, obj.selectedService)){
					priceTimeobj =  this.calcPriceTimeForPrvdr(field, locType);
					price += priceTimeobj.price;
					time += priceTimeobj.time;
				}
			}else {
				if(objSepFieldValue == 'add_as_separate_charge'){
					priceTimeobj = this.calcSeprateChargeFields(field, locType);
					price += priceTimeobj.price;
					time += priceTimeobj.time;
					priceExcFromServPrvTotal += priceTimeobj.priceExcFromServPrvTotal;
				}else if (objSepFieldValue == '') {
					priceTimeobj = this.calcPriceTimeCustomFields(field, locType);
					price += priceTimeobj.price;
					time += priceTimeobj.time;
					priceExcFromServPrvTotal += priceTimeobj.priceExcFromServPrvTotal;
				}
			}
		}
		return {
			price,
			time,
			priceExcFromServPrvTotal,
		}
	}
	private prvdrPayNotNone(providerPayType: any){
		if(providerPayType && providerPayType != 'none'){
			return true;
		}
		return false;
	}
	/**
	 * Function calculates separate charge fields based on the provided field and location type.
	 * If the field has a property add_as_separate_charge set to true, it calls the calcPriceTimeCustomFields function passing the field and locType as parameters.
	 * The result is stored in the priceTimeobj variable.
	 * The function then checks the separate_charge_type property of the field and performs different actions based on its value.
	 * If it is 'price', it adds the price and priceExcFromServPrvTotal from priceTimeobj to the corresponding variables.
	 * If it is 'time', it adds the time from priceTimeobj to the time variable.
	 * If it is any other value, it adds the price and time from priceTimeobj to the corresponding variables, as well as the priceExcFromServPrvTotal to priceExcFromServPrvTotal.
	 * Finally, it returns an object with the calculated price, time, and priceExcFromServPrvTotal.
	 * @param field
	 * @param locType
	 * @returns
	 */
	public calcSeprateChargeFields(field: any, locType: any){
		let price = 0;
		let time = 0;
		let priceExcFromServPrvTotal = 0;
		if(field?.add_as_separate_charge){
			let priceTimeobj = this.calcPriceTimeCustomFields(field, locType);
			switch(field?.separate_charge_type){
				case 'price':
					price += priceTimeobj.price;
					priceExcFromServPrvTotal += priceTimeobj.priceExcFromServPrvTotal;
				break;
				case 'time':
					time += priceTimeobj.time;
				break;
				default:
					price += priceTimeobj.price;
					time += priceTimeobj.time;
					priceExcFromServPrvTotal += priceTimeobj.priceExcFromServPrvTotal;
				break;
			}
		}
		return {
			price,
			time,
			priceExcFromServPrvTotal,
		}
	}
	/**
	 * Function checks if the provided field has a value.
	 * If the field exists, has a value, and the value's length is greater than 0, it returns true.
	 * Otherwise, it returns false.
	 * @param field
	 * @returns
	 */
	public fieldHasValue(field: any){
		if(field && field?.value && (field.value).length > 0){
			return true;
		}
		return false;
	}
	/**
	 * Function checks if the provided selected service is not an hourly service and is not set to override pricing.
	 * If both conditions are met, it returns true, otherwise it returns false.
	 * @param selectedService
	 * @returns
	 */
	public servNotHourlyNotOverriden(selectedService: any){
		if(selectedService?.is_hourly_service != 'yes' && selectedService?.is_override_pricing != 'yes'){
			return true;
		}
		return false;
	}
	/**
	 * Function returns true if any of the following conditions are met:
	 * The selected service is an hourly service and either the field is marked as a separate charge and the separate charge type is not 'time', or the field type is 'after_discounted_total'.
	 * The selected service has override pricing enabled and the field is marked as exempt from frequency discount, or the field type is 'after_discounted_total'.
	 * If none of the above conditions are met, the function returns false.
	 * @param field
	 * @param fieldType
	 * @param selectedService
	 * @returns
	 */
	public isServHrlyOrOvrrideAndSeprChrNotTimeOrAftrDisc(field: any, fieldType: any, selectedService: any){
		if(this.hourlyServiceAndSeprChrNotTimeOrAftrDisc(selectedService, field, fieldType)|| this.isServOverrideAndExemptFrmFreq(field, fieldType, selectedService)){
			return true;
		}
		return false;
	}
	private hourlyServiceAndSeprChrNotTimeOrAftrDisc(selectedService: any, field: any, fieldType: any){
		if(selectedService?.is_hourly_service == 'yes' && this.isSeprChrNotTimeOrAftrDisc(field, fieldType)){
			return true;
		}
		return false;
	}
	public isSeprChrNotTimeOrAftrDisc(field: any, fieldType: any){
		if(this.addAsSeprateAndNotTime(field) || fieldType == 'after_discounted_total'){
			return true;
		}
		return false;
	}
	private addAsSeprateAndNotTime(field: any){
		if(field?.add_as_separate_charge && field?.separate_charge_type != 'time'){
			return true;
		}
		return false;
	}
	/**
	 * Function checks if a selected service is exempt from frequency discount based on the field, field type, and selected service object.
	 * If the selected service has override pricing enabled and the field is marked as exempt from frequency discount or
	 * the field type is 'after_discounted_total', the function returns true. Otherwise, it returns false.
	 * @param field
	 * @param fieldType
	 * @param selectedService
	 * @returns
	 */
	public isServOverrideAndExemptFrmFreq(field: any, fieldType: any, selectedService: any){
		if(selectedService?.is_override_pricing == 'yes' && this.isFieldIncludeExemptOrAfterDisc(field, fieldType)){
			return true;
		}
		return false;
	}
	private isFieldIncludeExemptOrAfterDisc(field: any, fieldType: any){
		if(this.isFieldIncludeOrExempt(field) || fieldType == 'after_discounted_total'){
			return true;
		}
		return false;
	}
	private isFieldIncludeOrExempt(field: any){
		if(field?.add_price_in == "include_in_freq_disc" || field?.add_price_in == "exempt_from_freq_disc"){
			return true;
		}
		return false;
	}
	/**
	 * Function calculates the price, time, and price excluding from service provider total for a selected field.
	 * It takes in two parameters: field and locType.
	 * If the field has a value, the function checks the locType ('ML' or 'SA') and retrieves the price and time objects accordingly.
	 * The function then checks the field type and performs different actions based on the type.
	 * For 'Dropdown' or 'Radio' fields, it sets the time and price based on the selected value from the field.
	 * For 'Checkbox' fields, it calculates the price and time based on the selected checkboxes.
	 * For 'Priceable Input' fields, it sets the time to 0 and retrieves the price directly from the field value.
	 * The function returns an object containing the calculated price, time, and price excluding from service provider total.
	 * @param field
	 * @param locType
	 * @returns
	 */
	// eslint-disable-next-line complexity
	public calcPriceTimeCustomFields(field: any, locType: any){
		let price = 0;
		let time = 0;
		let priceExcFromServPrvTotal = 0;
		if(this.fieldHasValue(field) && this.valueNotNull(field)){
			let checkboxObj: any;
			let quantityBasedObj : any;
			let fieldPriceObj = locType == 'ML' ? field?.prices_ml : field?.prices_sa;
			let fieldTimeObj = locType == 'ML' ? field?.times_ml : field?.times_sa;
			switch(field?.field_type){
				case 'Dropdown':
				case 'Radio':
					time = fieldTimeObj[field?.value[0]]['val'];
					if(fieldPriceObj[field?.value[0]]['type'] != 'percentage'){
						price = fieldPriceObj[field?.value[0]]['val'];
					}else{
						// Percentage block
					}
					priceExcFromServPrvTotal = this.setPriceExcFromServPrvTotal(field, price);
				break;
				case 'Checkbox':
					checkboxObj = this.getCheckBoxPriceTime(field, fieldPriceObj, fieldTimeObj);
					price = checkboxObj.price;
					time = checkboxObj.time;
					priceExcFromServPrvTotal = this.setPriceExcFromServPrvTotal(field, checkboxObj.price);
				break;
				case 'Priceable Input':
					time = 0;
					price = +field?.value[0];
					priceExcFromServPrvTotal = this.setPriceExcFromServPrvTotal(field, price);
				break;
				case 'Quantity Based':
					quantityBasedObj = this.getQuantityBasedPriceTime(field, locType);
					price = quantityBasedObj.price;
					time = quantityBasedObj.time;
					priceExcFromServPrvTotal = this.setPriceExcFromServPrvTotal(field, price);
				break;
			}
		}
		return {
			price,
			time,
			priceExcFromServPrvTotal,
		}
	}
	private getQuantityBasedPriceTime(field: any, locType: any){
		let price = 0;
		let time = 0;
		// eslint-disable-next-line no-case-declarations
		let quantityBasedPriceObj = locType == 'ML' ? field?.quantity_details?.prices_ml : field?.quantity_details?.prices_sa;
		// eslint-disable-next-line no-case-declarations
		let quantityBasedTimeObj = locType == 'ML' ? field?.quantity_details?.times_ml : field?.quantity_details?.times_sa;
		if(field?.quantity_details?.enable_quantity_based == "yes" && field?.quantity_details?.qty_based_price_length == "yes"){
			let quantityBasedObj = this.priceCalQuantityBasedForMultiplyAndManual(field, quantityBasedPriceObj, quantityBasedTimeObj);
			price = quantityBasedObj.price;
			time = quantityBasedObj.time;
		}else{
			time = quantityBasedTimeObj[0];
			if(field?.quantity_details?.amount_type != 'percentage'){
				price = quantityBasedPriceObj[0];
			}else{
				// Percentage block
			}
		}
		return {
         price,
         time,
      }
	}
	private priceCalQuantityBasedForMultiplyAndManual(field: any, quantityBasedPriceObj: any, quantityBasedTimeObj: any){
		let price = 0;
		let time = 0;
		if(field?.quantity_details?.qty_based_price_length_type == 'manual'){
			let quantityIndex = (+field?.value[0]) - 1;
			time = quantityBasedTimeObj ? quantityBasedTimeObj[quantityIndex] : 0;
			if(field?.quantity_details?.amount_type != 'percentage'){
				price = quantityBasedPriceObj[quantityIndex];
			}else{
				// Percentage block
			}
		}else{
			time = quantityBasedTimeObj ? quantityBasedTimeObj[0]*(+field?.value[0]) : 0;
			if(field?.quantity_details?.amount_type != 'percentage'){
				price = quantityBasedPriceObj[0]*(+field?.value[0]);
			}else{
				// Percentage block
			}
		}
		return {
			price, time
		}
	}
	/**
	 * Function setPriceExcFromServPrvTotal takes in two parameters: field and value.
	 * It checks if the provider_pay_type property of the field object is not equal to 'none'.
	 * If it is not equal to 'none', the function returns the value parameter.
	 * If the provider_pay_type property is equal to 'none', the function returns 0.
	 * @param field
	 * @param value
	 * @returns
	 */
	public setPriceExcFromServPrvTotal(field: any, value: any){
		if(field?.provider_pay_type != 'none'){
			return value;
		}
		return 0;
	}
	/**
	 * Function getCheckBoxPriceTime takes in three parameters:
	 * field - an object containing a value property,
	 * fieldPriceObj - an object containing price values for different options,
	 * fieldTimeObj - an object containing time values for different options.
	 * The function initializes two variables, price and time, with values of 0.
	 * It then checks if the field.value property exists and its length is greater than 0. If so, it iterates over each element val in field.value.
	 * If val exists, it adds the corresponding time value from fieldTimeObj to time variable.
	 * Additionally, if the price type for this val is not 'percentage', it adds the corresponding price value from fieldPriceObj to price variable
	 * Finally, the function returns an object with two properties: price and time, containing the calculated values.
	 * @param field
	 * @param fieldPriceObj
	 * @param fieldTimeObj
	 * @returns
	 */
	public getCheckBoxPriceTime(field: any, fieldPriceObj: any, fieldTimeObj: any){
		let price = 0;
		let time = 0;
		if(field?.value && (field.value).length > 0){
			for(let val of field.value){
				if(val){
					time += (fieldTimeObj?.[val]?.['val'] ? fieldTimeObj?.[val]?.['val']: 0);
					if(fieldPriceObj?.[val]?.['type'] != 'percentage'){
						price += fieldPriceObj[val]['val'];
					}else{
						// Percentage block
					}
				}
			}
		}
		return {
			price,
			time,
		}
	}
	/**
	 * Function iterates over each element "type" in "typeArray". If "BKFrmValue?.priceable_custom_fields?.[type]" exists,
	 * it assigns "BKFrmValue?.location_type" to a variable "locType".
	 * For each element "field" in "BKFrmValue.priceable_custom_fields[type]", the function checks if "applyTo" is equal to the
	 * value of "field?.apply_to" or if "applyTo" is an empty string. If either condition is true,
	 * it calls the function "getPriceableFieldExcTime" with the appropriate parameters and adds the resulting value to "time".
	 * Finally, the function returns an object with a single property "time" and its value.
	 * @param BKFrmValue
	 * @param applyTo
	 * @param seprateFieldValue
	 * @param timeType
	 * @returns
	 */
	public calcPriceableCustomFieldsExcTime(BKFrmValue: any, applyTo: any = '', seprateFieldValue: string = '', timeType: string = 'exclude_time'){
		let time = 0;
		// let typeArray = ['include_in_freq_disc', 'exempt_from_freq_disc', 'non_taxable','after_discounted_total'];
		let typeArray = ['include_in_freq_disc', 'exempt_from_freq_disc'];
		for (let type of typeArray){
			if(BKFrmValue?.priceable_custom_fields?.[type]){
				let locType = BKFrmValue?.location_type;
				for(let field of BKFrmValue.priceable_custom_fields[type]){
					if((applyTo == field?.apply_to) || applyTo == ''){
						time += this.getPriceableFieldExcTime(field, seprateFieldValue, timeType, locType);
					}
				}
			}
		}
		return {
			time
		};
	}
	/**
	 * Function checks if the value of "seprateFieldValue" is equal to 'add_as_separate_charge' and if the "field.add_as_separate_charge" is true.
	 * It also checks if either the "timeType" is 'exclude_time' and the "field.separate_charge_type" is 'price', or
	 * if the "timeType" is 'add_time' and the "field.separate_charge_type" is not 'price'.
	 * If all of these conditions are met, it calls the function "calcPriceTimeCustomFields" with the parameters "field" and "locType",
	 * and returns the value of "priceTimeobj.time". Otherwise, it returns 0.
	 * @param field
	 * @param seprateFieldValue
	 * @param timeType
	 * @param locType
	 * @returns
	 */
	public getPriceableFieldExcTime(field: any, seprateFieldValue: any, timeType: any, locType: any){
		if (this.isAddSeprateCharge(seprateFieldValue, field) && this.accToTimeTypeForField(field, timeType)){
			let priceTimeobj = this.calcPriceTimeCustomFields(field, locType);
			return priceTimeobj.time;
		}
		return 0;
	}
	public isTimeTypeExcludeAndFieldSeprTypePrice(field: any, timeType: any){
		if(timeType == 'exclude_time' && field?.separate_charge_type == 'price'){
			return true;
		}
		return false;
	}
	public isTimeTypeAddAndFieldSeprTypeNotPrice(field: any, timeType: any){
		if(timeType == 'add_time' && field?.separate_charge_type != 'price'){
			return true;
		}
		return false;
	}
	private isAddSeprateCharge(seprateFieldValue: any, field: any){
		if(seprateFieldValue == 'add_as_separate_charge' && field?.add_as_separate_charge){
			return true;
		}
		return false;
	}
	private accToTimeTypeForField(field: any, timeType: any){
		if(this.isTimeTypeExcludeAndFieldSeprTypePrice(field, timeType) || this.isTimeTypeAddAndFieldSeprTypeNotPrice(field, timeType)){
			return true;
		}
		return false;
	}
	/**
	 * Function calculates the price and time values for a provider based on the selected service and priceable custom fields.
	 * If the "BKFrmValue.priceable_custom_fields" exist, it calls the function "calcPriceableFieldAccToType" with
	 * the parameters "BKFrmValue" and "obj", and assigns the returned object to the variable "priceTimeObj".
	 * Then, it adds the value of "priceTimeObj.price" to the variable "price" and the value of "priceTimeObj.time" to the variable "time".
	 * Finally, it returns an object with the calculated values of "price" and "time".
	 * @param BKFrmValue
	 * @param type
	 * @param seprateFieldValue
	 * @returns
	 */
	public calcPrvdrValFromPriceableFields(BKFrmValue: any, selectedService: any, type: string, seprateFieldValue: string = ''){
		let price = 0;
		let time = 0;
		let obj = {
			type : type,
			applyTo : '',
			selectedService: selectedService,
			seprateFieldValue : seprateFieldValue,
			isProvider: true
		}
		if(BKFrmValue?.priceable_custom_fields){
			let priceTimeObj = this.calcPriceableFieldAccToType(BKFrmValue, obj)
			price += priceTimeObj.price;
			time += priceTimeObj.time;
		}
		return {
			price,
			time,
		}
	}
	/**
	 * CalcPriceTimeForPrvdr that takes two parameters: "field" and "locType".
	 * It checks if the value of "field.provider_pay_type" is not equal to 'none'.
	 * If it is not 'none', it calls another function "calcPriceTimeCustomFields" with the parameters "field" and "locType"
	 * and assigns the returned object to the variable "priceTimeobj".
	 * The function then adds the value of "priceTimeobj.time" to the variable "time".
	 * If the value of "field.provider_pay_type" is 'full', it adds the value of "priceTimeobj.price" to the variable "price".
	 * Otherwise, it checks if the value of "field.provider_pay_unit" is 'percentage'. If it is,
	 * it calculates the amount by multiplying "priceTimeobj.price" with "field.provider_pay_amount" divided by 100, and adds it to the variable "price".
	 * If the value of "field.provider_pay_unit" is not 'percentage', it directly adds the value of "field.provider_pay_amount" to the variable "price".
	 * Finally, the function returns an object with the calculated values of "price" and "time".
	 * @param field
	 * @param obj
	 * @param locType
	 * @returns
	 */
	public calcPriceTimeForPrvdr(field: any, locType: any){
		let price = 0;
		let time = 0;
		if(field?.provider_pay_type != 'none'){
			let priceTimeobj = this.calcPriceTimeCustomFields(field, locType);
			time += priceTimeobj.time;
			if(field?.provider_pay_type == 'full'){
				price += priceTimeobj.price;
			}else{
				if(field?.provider_pay_unit == 'percentage'){
					let amount = (priceTimeobj.price) * (field?.provider_pay_amount / 100);
					price += amount;
				}else{
					price += field?.provider_pay_amount;
				}
			}
		}
		return {
			price,
			time,
		}
	}

	/**
	 * Function named "isFieldValue" that takes three parameters: "field", "fieldType", and "selectedService". The function checks multiple conditions
	 * to determine whether a field value is valid or not. If the field value exists and has a length greater than 0, and if one of the following
	 * conditions is true:

	 * 1.The selected service is neither hourly nor overridden, or
	 * 2.The selected service is hourly and the field seprate charge type is not time or after discount OR service overridden and the field type is exempt from frequency or after discount, or
	 * 3.The field is not taxable

	 * If any of these conditions are met, the function returns true. Otherwise, it returns false.
	 * @param field
	 * @param fieldType
	 * @returns
	 */
	public isFieldValue(field: any, fieldType: any, selectedService: any){
		if(field?.value?.length > 0 && this.accToserviceTypeAndFieldTypeCheck(selectedService, field, fieldType)){
			return true;
		}
		return false;
	}
	private accToserviceTypeAndFieldTypeCheck(selectedService: any, field: any, fieldType: any){
		if(this.servNotHourlyNotOverriden(selectedService) || this.isServHrlyOrOvrrideAndSeprChrNotTimeOrAftrDisc(field, fieldType, selectedService) || !field?.is_taxable){
			return true;
		}
		return false;
	}
	/**
	 * FieldValue function takes in two parameters: "field" and "locType". It initializes a variable "fieldPrice" with a value of 0.
	 * It then checks the value of "field.field_type" using a switch statement.
	 * If it is 'Checkbox', it iterates through the values in "field.value" and adds the corresponding price from "fieldPriceObj" to "fieldPrice".
	 * If it is 'Priceable Input', it assigns the first value from "field.value" to "fieldPrice" as a number.
	 * Otherwise, it assigns the corresponding price from "fieldPriceObj" to "fieldPrice". Finally, it returns the calculated "fieldPrice".
	 * @param field
	 * @returns
	 */
	public fieldValue(field: any, locType: any){
		let fieldPrice = 0;
		let fieldPriceObj = locType == 'ML' ? field?.prices_ml : field?.prices_sa;
		switch(field?.field_type){
			case 'Checkbox':
				for(let fieldVal of field.value){
					if(fieldPriceObj[fieldVal].type != 'percentage'){
						fieldPrice += fieldPriceObj[fieldVal].val;
					}else{
						// percentage block
					}
				}
				return fieldPrice;
			case 'Priceable Input':
				return +field?.value[0];
			case 'Quantity Based':
				// eslint-disable-next-line no-case-declarations
				let quantityBasedObj = this.getQuantityBasedPriceTime(field, locType);
				fieldPrice = quantityBasedObj.price;
				return fieldPrice;
			default :
				if(this.valueNotNull(field) && fieldPriceObj[field?.value[0]].type != 'percentage'){
					fieldPrice = fieldPriceObj[field?.value[0]].val
				}else{
					// percentage block
				}
				return fieldPrice
		}
	}
	private valueNotNull(field: any){
		if(field?.value[0] && field?.value[0] != 'null'){
			return true;
		}
		return false;
	}
	/**
	 * SetFieldObj function takes in two parameters: "field" and "locType".
	 * It checks the value of "field.field_type" using a switch statement.
	 * If it is 'Checkbox', it iterates through the values in "field.value" and adds the corresponding price from "fieldPriceObj" to "fieldPrice".
	 * If it is 'Priceable Input', it assigns the first value from "field.value" to "fieldPrice" as a number.
	 * Otherwise, it assigns the corresponding price from "fieldPriceObj" to "fieldPrice". Finally, it returns the calculated "fieldPrice"
	 * @param field
	 * @returns
	 */
	public setFieldObj(field: any, locType: any){
		let fieldPrice = 0;
		let fieldPriceObj = locType == 'ML' ? field?.prices_ml : field?.prices_sa;
		let quantityBasedObj : any;
		switch(field?.field_type){
			case 'Checkbox':
				for(let fieldVal of field.value){
					let fieldValue = fieldPriceObj[fieldVal];
					fieldPrice = this.calculateFieldPrice(fieldValue, fieldPrice)
				}
				return {fieldName: field?.name, fieldPrice: fieldPrice};
			case 'Priceable Input':
				return {fieldName: field?.name, fieldPrice: +field?.value[0]};
			case 'Quantity Based':
				quantityBasedObj = this.getQuantityBasedPriceTime(field, locType);
				return {fieldName: field?.name, fieldPrice: quantityBasedObj.price};
			default :
				// eslint-disable-next-line no-case-declarations
				let fieldValue = fieldPriceObj[field?.value[0]];
				fieldPrice = this.calculateFieldPrice(fieldValue, fieldPrice)
				return {fieldName: field?.name, fieldPrice: fieldPrice};
		}
	}
	/**
	 * function is used to calculate the field price based on the field value passed as a parameter.
	 * It first checks if the field value type is not "percentage", and if so, it adds the field value to the field price.
	 * It then returns the updated field price.
	 * @param fieldValue
	 * @param fieldPrice
	 * @returns
	 */
	private calculateFieldPrice(fieldValue: any, fieldPrice: number){
		if(fieldValue && fieldValue?.type != 'percentage'){
			fieldPrice += fieldValue.val;
		}else{
			// percentage block
		}
		return fieldPrice;
	}
	/**
	 * SetPrvdrFieldObj func takes parameters: "field" andfieldPrice". Inside the, it initializes a variable "provdrPrice" with value of "fieldPrice".
	 * Next, checks if "field.provider_pay_type" is equal 'partial'. If it is, it checks value of "field.provider_pay_unit".
	 * If is equal to 'percentage it calculates the "provdrPrice" by multiplying "fieldPrice" by "field.provider_pay_amount" divided by 100.
	 * Otherwise, it converts the value of "field.provider_pay_amount" to a number and assigns it to "provdrPrice".
	 * Finally, it returns an object with two properties: "fieldName" which is the value of "field.name",
	 * and "fieldPrice" which is the calculated "provdrPrice"
	 * @param field
	 * @param fieldPrice
	 * @returns
	 */
	public setPrvdrFieldObj(field: any, fieldPrice: any){
		let provdrPrice = fieldPrice;
		if(field?.provider_pay_type == 'partial'){
			if(field?.provider_pay_unit == 'percentage'){
				provdrPrice = (fieldPrice) * (field?.provider_pay_amount / 100);
			}else{
				provdrPrice = +field?.provider_pay_amount;
			}
		}
		return {fieldName: field?.name, fieldPrice: provdrPrice};
	}
}
